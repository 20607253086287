import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import parameters from '../parameters.json'
import '../styles/clientResult.css'

const ClientResult = () => {
    const { clientAnswerHeaderId } = useParams();
    const [result, setResult] = useState(null);

    useEffect(() => {
        fetch(parameters.BASE_API_URL
            + '/ClientAssessment/gradeClientAnswer?clientAnswerHeaderId='
            + clientAnswerHeaderId
        )
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setResult(data);
            })
            .catch((res) => {
                console.error(res);
            });
    }, [clientAnswerHeaderId])

    const ClientResult = () => {
        if (result && result.client) {
            return (
                <div className='row'>
                    <h3>
                        {result.client.name} {result.client.surname} {result.client.nickName}
                    </h3>
                    <h5>
                        {result.assessmentName} sonuçları
                    </h5>
                    {
                        result.results.map((categoryResult, ixx) => {
                            return (
                                <div key={ixx} className='col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
                                    <div className="card mb-3">
                                        <div className="card-header">
                                            <strong>{categoryResult.title}:</strong> {categoryResult.result}
                                        </div>
                                        {
                                            categoryResult.resultDescription === '' ?
                                                null :
                                                <div className="card-body">
                                                    <p>{categoryResult.resultDescription}</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            )
        }
        else {
            return (
                <div className="full-page-container">
                    Katılımınız için teşekkür ederiz...
                    <a href='https://www.tim.com.tr/'><b>Tim Danışmanlık</b></a>
                </div>
            )
        }
    }

    return (
        <ClientResult />
    )
}

export default ClientResult;