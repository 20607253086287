import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import '../styles/assessment.css'
import parameters from '../parameters.json'
import { showAlert } from '../features/alert/alertSlice';
import { useDispatch } from 'react-redux';
import RequestButton from "../components/RequestButton";
import OptionValue from '../components/OptionValue';

const Assessment = () => {
    const baseUrl = parameters.BASE_API_URL;

    const navigate = useNavigate();
    const { assessmentId, sessionId } = useParams();

    const [assessment, setAssessment] = useState(null);
    const [needToClientGroupCode, setNeedToClientGroupCode] = useState(false);
    const [answers, setAnswers] = useState({});

    const [joinedStatu, setJoinedStatu] = useState(false)
    const [clientAnswerHeaderId, setClientAnswerHeaderId] = useState(null)
    const [sendRequested, setSendRequested] = useState(false)

    const [nickName, setNickName] = useState('');
    const [groupCode, setGroupCode] = useState('');

    const [pageInformationStatu, setPageInformationStatu] = useState(0)

    const dispatch = useDispatch();

    useEffect(() => {
        const joinedSessions = localStorage.getItem('joinedSessions')

        let solvedBefore = false;
        if (joinedSessions) {
            var obj = JSON.parse(joinedSessions)
            let solvedSession = obj.sessions.find(x => x.sessionId === sessionId)
            if (solvedSession) {
                solvedBefore = true
                setClientAnswerHeaderId(solvedSession.clientAnswerHeaderId)
            }
        }

        if (solvedBefore) {
            setJoinedStatu(solvedBefore)
        }
        else {
            setJoinedStatu(solvedBefore)

            fetch(parameters.BASE_API_URL
                + '/assessment/getBySessionId?sessionId='
                + sessionId
            )
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    setAssessment(data.assessment);
                    setNeedToClientGroupCode(data.needToClientGroupCode);

                    if (!data.assessment)
                        setPageInformationStatu(1);
                    else
                        setPageInformationStatu(2);
                })
                .catch((res) => {
                    console.error(res);
                });
        }
    }, [assessmentId, sessionId])

    const handleOptionChange = (questionId, optionId) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: optionId
        }));
    };

    const PageInformationText = () => {
        var message = null;
        switch (pageInformationStatu) {
            case 0:
                message = <p>Oturum yükleniyor. Lütfen bekleyin...</p>
                break;
            case 1:
                message = <> <p>Katılmak istediğiniz oturum sonlanmıştır.</p><p> Yeni oturumlarda görüşmek üzere...  </p><a href='www.tim.com.tr'>Tim Danışmanlık</a></>
                break;
            case 2:
                message = <></>
                break;
            default:
                message = <p>Oturum yükleniyor. Lütfen bekleyin...</p>
                break;
        }

        return (
            <div className='text-center'>
                {message}
            </div>
        )
    }

    const Title = () => {
        if (assessment == null)
            return <></>

        return (
            <div id='title-container' className='mb-3'>
                <h1>{assessment.name}</h1>
            </div>
        )
    }

    const Info = () => {
        if (assessment == null)
            return <></>

        return (
            <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
                <div className='question-card card'>
                    <div className='card-header mb-3'>
                        <b>Bilgilendirme</b>
                    </div>
                    <div className='card-context-container'>
                        {renderDescription()}
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="nick-name-input">Takma isim</span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="NickName"
                                aria-describedby="nick-name-input"
                                placeholder='Örneğin: TM'
                                id='nickname-input'
                                onChange={(e) => setNickName(e.target.value)}
                            />
                        </div>
                        {
                            needToClientGroupCode ?
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="group-code-input">Şube kodu</span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        aria-label="GroupCode"
                                        aria-describedby="group-code-input"
                                        placeholder='Örneğin: TİM-01'
                                        id='groupcode-input'
                                        onChange={(e) => setGroupCode(e.target.value)}
                                    />
                                </div> :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }

    const renderDescription = () => {
        return <div dangerouslySetInnerHTML={{ __html: assessment.information }}></div>
    }

    const getOptionClasses = (question) => {
        switch (question.questionOptionDisplayType) {
            case 0:
                return "form-check col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
            case 1:
                return "form-check col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1"
            case 2:
                return "form-check col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2"
            default:
                return "form-check col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
        }
    }

    const Questions = () => {
        if (assessment == null) {
            return <></>
        }

        return (
            <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
                {
                    assessment.questions.map((question, index) => (
                        <div className='question-card card' key={question.id} id={question.id + '-question-card'}>
                            <div className='card-header'>
                                <b>{index + 1}</b> - {question.text}
                            </div>
                            <div key={question.id} className="option-container p-2">
                                <div className='row'>
                                    {
                                        assessment.optionType === 0 ?
                                            question.options.map(option => (
                                                <div className={getOptionClasses(question)} key={option.id} >
                                                    <input
                                                        className="form-check-input"
                                                        checked={answers[question.id] === option.id}
                                                        onChange={() => handleOptionChange(question.id, option.id)}
                                                        type="radio"
                                                        name={question.id}
                                                        id={option.id}
                                                    />
                                                    <label className="form-check-label" htmlFor={option.id}>
                                                        {option.text}
                                                    </label>
                                                </div>
                                            )) :
                                            <OptionValue question={question} optionValues={assessment.optionValues} optionValueType={assessment.optionValues[0].valueType} />
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    const SendButton = () => {
        if (!answers || !assessment)
            return <></>

        if (assessment && !assessment.isActive)
            return <></>

        return (
            <>
                <RequestButton
                    classNames='btn btn-warning mb-1'
                    onClick={() => sendAnswer()}
                    isOnRequest={sendRequested}
                >
                    Gönder
                </RequestButton>
            </>
        )
    }

    const sendAnswer = () => {
        const answerList = getAnswers(assessment.optionType)

        const valid = validateAssessment(answerList)
        if (!valid)
            return;

        setSendRequested(true)

        const answer = {
            client: {
                nickName: nickName,
                groupCode: groupCode
            },
            answers: answerList,
            assessmentId: assessment.id,
            sessionId: sessionId
        }

        fetch(baseUrl + '/ClientAssessment',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(answer)
            })
            .then((res) => {
                if (res.ok) {
                    return res.text();
                }
                else {
                    setSendRequested(false)
                    dispatch(showAlert({ type: 'danger', text: 'Yanıtlarınız sisteme kayıt edilemedi.' }))
                }
            })
            .then((clientAnswerHeaderId) => {
                if (clientAnswerHeaderId[0] === '"')
                    clientAnswerHeaderId = clientAnswerHeaderId.slice(1, -1)

                saveJoinedSessionToLocalStorage(sessionId, clientAnswerHeaderId)
                navigate('/clientResult/' + clientAnswerHeaderId)
            })
            .catch((err) => {
                setSendRequested(false)
                console.error(err)
            });
    }

    const validateAssessment = (answers) => {
        // check nickName
        if (!nickName) {
            let nickNameInput = document.getElementById('nickname-input');

            let nickNameInputTopPos = nickNameInput.offsetTop;
            window.scrollTo(0, nickNameInputTopPos)

            dispatch(showAlert({ type: 'warning', text: 'Bir takma isim girmelisiniz' }))
            return false;
        }

        // chech groupCode
        if (needToClientGroupCode && groupCode === '') {
            let groupCodeInput = document.getElementById('groupcode-input');

            let groupCodeInputTopPos = groupCodeInput.offsetTop;
            window.scrollTo(0, groupCodeInputTopPos)

            dispatch(showAlert({ type: 'warning', text: 'Bir grup kodu girmelisiniz' }))
            return false;
        }

        // check if any unanswered question
        if (assessment.optionType === 0) {
            const answerQuestionIdList = answers.map((a) => a.questionId)
            const questionIdList = assessment.questions.map((q) => q.id)
            const unansweredQuestionIds = questionIdList.filter((q) => !answerQuestionIdList.includes(q));

            if (unansweredQuestionIds.length > 0) {
                let firstUnansweredQuestion = document.getElementById(unansweredQuestionIds[0] + '-question-card');

                let topPos = firstUnansweredQuestion.offsetTop;
                window.scrollTo(0, topPos)

                let questionOrder = assessment.questions.find(x => x.id === unansweredQuestionIds[0])
                if (questionOrder)
                    dispatch(showAlert({ type: 'warning', text: questionOrder.orderNumber + '. soruyu yanıtlayınız' }))

                return false;
            }
        } else if (assessment.optionType === 1) {
            if (assessment.optionValues[0].valueType === 1) {
                let grouped = answers.reduce((acc, curr) => {
                    if (!acc[curr.questionId]) {
                        acc[curr.questionId] = 0;
                    }
                    acc[curr.questionId] += curr.value;
                    return acc;
                }, {});

                let invalidQuestionIds = Object.entries(grouped)
                    .filter(([questionId, total]) => total !== assessment.optionValues[0].sumLimit)
                    .map(([questionId]) => questionId);

                if (invalidQuestionIds.length > 0) {
                    let firstInvalidQuestionId = invalidQuestionIds[0];

                    let firstUnansweredQuestion = document.getElementById(firstInvalidQuestionId + '-question-card');

                    let topPos = firstUnansweredQuestion.offsetTop;
                    window.scrollTo(0, topPos)

                    let questionOrder = assessment.questions.find(x => x.id === firstInvalidQuestionId)
                    if (questionOrder)
                        dispatch(showAlert({ type: 'warning', text: questionOrder.orderNumber + '. sorunun toplamı ' +  assessment.optionValues[0].sumLimit + ' yapmalı!'}))
                    
                    return false;
                }

            }
            
            for (let i = 0; i < answers.length; i++) {
                const answer = answers[i];
                if (answer.value === -999) {
                    let firstUnansweredQuestion = document.getElementById(answer.questionId + '-question-card');

                    let topPos = firstUnansweredQuestion.offsetTop;
                    window.scrollTo(0, topPos)

                    let questionOrder = assessment.questions.find(x => x.id === answer.questionId)
                    if (questionOrder)
                        dispatch(showAlert({ type: 'warning', text: questionOrder.orderNumber + '. soruyu yanıtlayınız' }))

                    return false;
                }
            }
        }

        return true;
    }

    const getAnswers = (optionType) => {
        if (optionType === 1) { // optionValue
            let answerList = []
            for (let i = 0; i < assessment.questions.length; i++) {
                const question = assessment.questions[i];
                for (let j = 0; j < question.options.length; j++) {
                    const option = question.options[j];

                    let optionSelect = document.getElementById(question.id + '_' + option.id);
                    let optionValue = optionSelect.value;

                    answerList.push({
                        questionId: question.id,
                        optionId: option.id,
                        value: parseInt(optionValue)
                    });
                }
            }

            return answerList;
        }
        else { // classical
            const answerList = Object.keys(answers).map((key) => {
                return { questionId: key, optionId: answers[key] }
            })

            return answerList;
        }
    }

    const saveJoinedSessionToLocalStorage = (sessionIdParam, clientAnswerHeaderIdParam) => {
        var joinedSessions = localStorage.getItem('joinedSessions')

        let obj
        if (joinedSessions) {
            obj = JSON.parse(joinedSessions)
            obj.sessions.push({
                sessionId: sessionIdParam,
                clientAnswerHeaderId: clientAnswerHeaderIdParam
            })
            localStorage.setItem('joinedSessions', JSON.stringify(obj))
        }
        else {
            obj = {
                sessions: [{
                    sessionId: sessionIdParam,
                    clientAnswerHeaderId: clientAnswerHeaderIdParam
                }]
            }
            localStorage.setItem('joinedSessions', JSON.stringify(obj))
        }
    }

    if (assessment != null && !assessment.isActive) {
        return (
            <div className='text-center'>
                <p>Giriş yapmak istediğiniz envanter aktif değil. Lütfen Tim Danışmanlık ile iletişime geçiniz.</p>
                <a href='https://www.tim.com.tr/'>Tim Danışmanlık</a>
            </div>
        )
    }

    if (joinedStatu) {
        return (
            <div className='text-center'>
                <p>Bu oturuma daha önce katıldığınızı görüyoruz. Gelecek oturumlarda görüşmek üzere.</p>
                <p>Dilerseniz sonucunuzu <a href={window.location.origin + '/clientResult/' + clientAnswerHeaderId}>buradan</a> görebilirsiniz.</p>
                <a href='https://www.tim.com.tr/'>Tim Danışmanlık</a>
            </div>
        )
    }

    return (
        <div id='assessment-page-container'>
            {PageInformationText()}
            {Title()}
            {Info()}
            {Questions()}
            {SendButton()}
        </div>
    )
};

export default Assessment;