import React, { useEffect, useState } from "react";
import parameters from '../parameters.json'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearToken, selectToken } from "../features/auth/authSlice";
import { showAlert } from "../features/alert/alertSlice";
import { resetUserDetail, selectRoles, selectUserDetail, setRoles, setUserDetail, setUserList } from "../features/auth/userSlice";
import RequestButton from "./RequestButton";

const UserDetail = () => {
    const baseUrl = parameters.BASE_API_URL;

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const token = useSelector(selectToken)
    const roles = useSelector(selectRoles)
    const userDetail = useSelector(selectUserDetail)

    const [saveRequested, setSaveRequested] = useState(false)

    useEffect(() => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/user/getRoles', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                if (data)
                    dispatch(setRoles(data))
            })
            .catch((res) => {
                console.error(res);
            });
    }, [token, baseUrl, dispatch, navigate])

    const GetUserList = () => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/user', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                if (data)
                    dispatch(setUserList(data))
            })
            .catch((res) => {
                console.error(res);
            });
    }

    const Save = () => {
        const userRequest = userDetail.userId ?
            {
                userId: userDetail.userId,
                password: userDetail.password,
                roleId: userDetail.roleId,
                isActive: userDetail.isActive
            } :
            {
                username: userDetail.username,
                password: userDetail.password,
                roleId: userDetail.roleId,
                isActive: userDetail.isActive
            }

        const option = {
            headers: {
                Authorization: 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(userRequest)
        }

        const url = userDetail.userId ?
            '/user/updateUser' :
            '/user/createUser'

        setSaveRequested(true)

        fetch(baseUrl + url, option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                if (res.status === 400) {
                    dispatch(showAlert({ type: 'warning', text: 'Kullanıcı kayıt edilemedi! Farklı bir kullanıcı adı deneyin.' }))
                    dispatch(resetUserDetail())
                }

                if (res.ok) {
                    dispatch(showAlert({ type: 'success', text: 'Kayıt edildi!' }))
                    dispatch(resetUserDetail())
                    GetUserList()
                }

                setSaveRequested(false)
            })
            .catch((err) => {
                setSaveRequested(false)
                console.error(err)
            });
    }

    const Cancel = () => {
        dispatch(resetUserDetail())
    }

    const SaveDisability = () => {
        return (!userDetail.username || !userDetail.password || !userDetail.roleId) &&
            (!userDetail.username || !userDetail.userId || !userDetail.roleId)
    }

    return (
        <div className='card mb-2'>
            <div className='card-header'>
                Kullanıcı detayı
            </div>
            <div className='card-body'>
                <div className="d-grid gap-3">

                    <input
                        type="text"
                        className="form-control"
                        aria-label="username"
                        aria-describedby="username-input"
                        value={userDetail.username}
                        disabled={userDetail.userId}
                        onChange={(e) => dispatch(setUserDetail({ ...userDetail, username: e.target.value }))}
                        placeholder="kullanıcı adı"
                    />

                    <input
                        type="password"
                        className="form-control"
                        aria-label="password"
                        aria-describedby="password-input"
                        value={userDetail.password}
                        onChange={(e) => dispatch(setUserDetail({ ...userDetail, password: e.target.value }))}
                        placeholder="şifre"
                    />

                    <select
                        className="form-select"
                        placeholder="rol seçiniz"
                        value={userDetail.roleId}
                        onChange={e => dispatch(setUserDetail({ ...userDetail, roleId: e.target.value }))}
                    >
                        {
                            roles.map((role, index) => {
                                return (
                                    <option key={index} id={role.id} value={role.id}>{role.name}</option>
                                )
                            })
                        }
                    </select>

                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="userDetailStatu"
                            checked={userDetail.isActive}
                            onChange={(e) => dispatch(setUserDetail({ ...userDetail, isActive: e.target.checked }))}
                        />
                        <label className="form-check-label" htmlFor="userDetailStatu">{userDetail.isActive ? 'Aktif' : 'Pasif'}</label>
                    </div>
                </div>
            </div>
            <div className="card-footer text-end">
                <RequestButton
                    classNames="btn btn-primary"
                    onClick={Save}
                    disabled={SaveDisability()}
                    isOnRequest={saveRequested}
                    style={{ marginRight: '5px' }}
                >
                    Kaydet
                </RequestButton>
                <button
                    className="btn btn-secondary"
                    onClick={Cancel}
                    type="button"
                >
                    İptal
                </button>
            </div>
        </div>
    )
}

export default UserDetail;