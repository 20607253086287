import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        userList: [],
        userDetail: {
            username: '',
            password: '',
            roleId: '',
            role: '',
            isActive: false,
            userId: null
        },
        roles: []
    },
    reducers: {
        setUserDetail: (state, action) => {
            let role = state.roles.find(x => x.id === action.payload.roleId)

            if (state.roles.length === 0 || !role) {
                state.userDetail = action.payload;
            }
            else {
                state.userDetail = { ...action.payload, role: role.name }
            }
        },
        setUserList: (state, action) => {
            state.userList = action.payload
        },
        setRoles: (state, action) => {
            state.roles = action.payload
            state.userDetail = {
                ...state.userDetail,
                roleId: action.payload[0].id,
                role: action.payload[0].name,
            }
        },
        resetUserDetail: (state) => {
            state.userDetail = {
                username: '',
                password: '',
                roleId: state.roles[0].id,
                role: state.roles[0].name,
                isActive: false,
                userId: null
            }
        }
    }
})

export const selectUserDetail = state => state.user.userDetail
export const selectUserList = state => state.user.userList
export const selectRoles = state => state.user.roles

export const {
    setUserDetail,
    setUserList,
    setRoles,
    resetUserDetail
} = userSlice.actions

export default userSlice.reducer