import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clearToken, isAuthenticated } from '../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux';
import { clearSessions } from '../features/session/sessionSlice';

const Nav = () => {
  const isAuthenticatedUser = useSelector(isAuthenticated)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let path = location.pathname.toLowerCase();
  if (path.includes('/assessment') || path.includes('/clientresult'))
    return <></>

  const NavBarButtons = () => {
    if (isAuthenticatedUser)
      return AuthenticatedUserNavButtons();
    else
      return UnauthenticatedUserNavButtons();
  }

  const AuthenticatedUserNavButtons = () => {
    return (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className={'nav-link' + (path.includes('/session') ? ' active' : '')} to={'/session'}>Oturum yönetimi</Link>
        </li>
        <li className="nav-item">
          <Link className={'nav-link' + (path.includes('/systemManagement') ? ' active' : '')} to={'/systemManagement'} >Sistem yönetimi</Link>
        </li>
      </ul>
    )
  }

  const UnauthenticatedUserNavButtons = () => {
    return (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
      </ul>
    )
  }

  const AuthButtons = () => {
    if (isAuthenticatedUser)
      return AuthenticatedUserAuthButtons();
    else
      return UnauthenticatedUserAuthButtons();
  }

  const AuthenticatedUserAuthButtons = () => {
    return (
      <form className="d-flex">
        <button className="btn btn-outline-light" type="button" onClick={() => Logout()}>Çıkış</button>
      </form>
    )
  }

  const UnauthenticatedUserAuthButtons = () => {
    return (
      <form className="d-flex">
        <button className="btn btn-outline-light" type="button">Giriş</button>
      </form>
    )
  }

  const Logout = () => {
    dispatch(clearToken())
    dispatch(clearSessions())
    navigate('/login')
  }

  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-primary p-2">
      <div className='container-fluid'>
        <Link className="navbar-brand" to={'/'}>Tim Akademi</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {NavBarButtons()}
          {AuthButtons()}
        </div>
      </div>
    </nav>
  );
}

export default Nav;
