import React, { useEffect } from "react";
import parameters from "../../parameters.json"
import { useDispatch, useSelector } from "react-redux";
import { selectCorporationList, setCorporationDetail, setCorporationList } from "../../features/entity/corporationSlice";
import { clearToken, selectToken } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { FaPen } from "react-icons/fa6";

const List = () => {
    const baseUrl = parameters.BASE_API_URL;
    const corporationList = useSelector(selectCorporationList)
    const token = useSelector(selectToken)

    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/appParameter/corporation/all', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                if (data)
                    dispatch(setCorporationList(data))
            })
            .catch((res) => {
                console.error(res);
            });
    }, [dispatch, navigate, baseUrl, token])

    const RenderCorporationListItems = () => {
        if (corporationList.length === 0)
            return (
                <tr><th>Kurum listesi çekiliyor...</th></tr>
            )

        return corporationList.map((corporation, index) => (
            <tr key={index} className='text-nowrap'>
                <td>{corporation.name}</td>
                <td>
                    <button className='btn btn-outline-secondary btn-sm' onClick={() => dispatch(setCorporationDetail(corporation))}><FaPen size={'1.7em'} /></button>
                    {/* <button className='btn btn-outline-danger btn-sm' onClick={() => dispatch(removeCorporation(corporation))}><FaTrash size={'1.7em'} /></button> */}
                </td>
            </tr>
        ))
    }

    return (
        <div className='card mb-2'>
            <div className='card-header'>
                Kurum listesi
            </div>
            <div className='card-body'>
                <table className='table table-striped m-3 table-responsive table-hover'>
                    <thead>
                        <tr>
                            <th scope="col">Kurum adı</th>
                            <th scope="col">Aksiyonlar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {RenderCorporationListItems()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default List;
