import React from "react";
import UserList from "../components/UserList";
import UserDetail from "../components/UserDetail";
import CorporationDetail from "../components/Corporation/Detail";
import CorporationList from "../components/Corporation/List";

const SystemManagement = () => {
    return (
        <div>
            <h1>Kullanıcı Yönetimi</h1>
            <hr />
            <div className='row'>
                <div className='col-md-4'>
                    <UserDetail />
                </div>
                <div className='col-md-8'>
                    <UserList />
                </div>
            </div>
            <br />
            <h1>Kurum Yönetimi</h1>
            <hr />
            <div className='row'>
                <div className='col-md-4'>
                    <CorporationDetail />
                </div>
                <div className='col-md-8'>
                    <CorporationList />
                </div>
            </div>
        </div>
    )
}

export default SystemManagement;