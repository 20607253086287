import { createSlice } from '@reduxjs/toolkit'

export const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        visibility: false,
        type: "info",
        element: null,
        text: null
    },
    reducers: {
        showAlert: (state, action) => {
            state.visibility = true
            state.type = action.payload.type
            state.element = action.payload.element
            state.text = action.payload.text
        },
        hideAlert: (state) => {
            state.visibility = false
            state.element = null
            state.text = null
        }
    }
})

export const alertVisibility = state => state.alert.visibility
export const alertType = state => state.alert.type
export const alertElement = state => state.alert.element
export const alertText = state => state.alert.text

export const { showAlert, hideAlert } = alertSlice.actions

export default alertSlice.reducer