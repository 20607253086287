import { createSlice } from '@reduxjs/toolkit'

let localStorageAppToken = localStorage.getItem('appToken')
let isUserAuthenticated = localStorageAppToken !== null

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    appToken: localStorageAppToken,
    isAuthenticated: isUserAuthenticated
  },
  reducers: {
    setToken: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.appToken = action.payload
      localStorage.setItem('appToken', action.payload)
      state.isAuthenticated = true
    },
    clearToken: state => {
      state.appToken = null
      state.isAuthenticated = false
      localStorage.removeItem('appToken')
    },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // }
  }
})

export const selectToken = state => state.auth.appToken
export const isAuthenticated = state => state.auth.isAuthenticated

export const { setToken, clearToken } = authSlice.actions

export default authSlice.reducer