import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertElement, alertText, alertType, alertVisibility, hideAlert } from "../features/alert/alertSlice";

import '../styles/alert.css'

const options = {
    delay: 5000
}

const Alert = () => {
    var dispatch = useDispatch()
    var visibility = useSelector(alertVisibility)
    var type = useSelector(alertType)
    var element = useSelector(alertElement)
    var text = useSelector(alertText)

    setTimeout(() => {
        dispatch(hideAlert())
    }, options.delay)

    var strongText = null
    switch (type) {
        case 'danger':
            strongText = <strong>Hata!</strong>
            break;
        case 'warning':
            strongText = <strong>Dikkat!</strong>
            break;
        case 'info':
            strongText = <strong>Bilginize!</strong>
            break;
        default:
            break;
    }

    var classNames = "alert fade show app-alert alert-" + type

    return (
        <div className={classNames} role="alert" hidden={!visibility}>
            {strongText} {element ?? text}
            {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
        </div>
    )
}

export default Alert