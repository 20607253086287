import React from 'react';

const SessionQrModal = ({ qrCode, link, sessionDescription }) => {
    return (
        <div className="modal fade" id="sessionQrModal" tabIndex="-1" aria-labelledby="sessionQrModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="sessionQrModalLabel">{sessionDescription}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <div className='row mb-3'>
                            <img src={qrCode} alt='assessment_qr' />
                        </div>
                        <div className='row text-wrap mb-3'>
                            <a target="_blank" href={link} rel="noopener noreferrer" >{sessionDescription}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionQrModal;