import React from 'react';

const SessionQrModalButton = ({ buttonInner, classNames, onClick }) => {
    return (
        <button type="button" className={classNames} data-bs-toggle="modal" data-bs-target="#sessionQrModal" onClick={() => onClick()}>
            {buttonInner}
        </button>
    )
}

export default SessionQrModalButton;
