import React from 'react';
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { isAuthenticated } from '../features/auth/authSlice';
import { useLocation, useNavigate } from "react-router-dom";

const AuthChecker = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const isAuthenticatedUser = useSelector(isAuthenticated)

    useEffect(() => {
        let path = location.pathname.toLowerCase();
        
        if (path.includes('/assessment') || path.includes('/clientresult')) {}
        else if (!isAuthenticatedUser && path === '/login') {}
        else if (isAuthenticatedUser && path === '/login') {
            navigate('/')
        }
        else if (isAuthenticatedUser) {}
        else {   
            navigate('/login')
        }
    }, [location, isAuthenticatedUser, navigate])

    return (
        <></>
    )
}

export default AuthChecker;