import React, { useState } from 'react';
import { FaRecycle } from 'react-icons/fa6';

import '../styles/optionValue.css'

const OptionValue = (props) => {
    const [selectedOptions, setSelectedOptions] = useState({});

    const handleSelectChange = (optionId, value) => {
        setSelectedOptions({ ...selectedOptions, [optionId]: value });
    };

    const clearSelectedOptions = () => {
        setSelectedOptions({})
    }
    
    return (
        <div className='row'>
            {props.question.options.map(option => {
                const disableOptions = Object.values(selectedOptions)
                    .filter((val, id) => val !== -999 && id !== option.id)
                    .map(score => parseInt(score, 10));

                return (
                    <div className='row mb-1' key={option.id}>
                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                            {option.text}
                        </div>
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <select
                                id={props.question.id + '_' + option.id}
                                className="form-select"
                                aria-label="Default select example"
                                value={selectedOptions[option.id] || -999}
                                onChange={(e) => handleSelectChange(option.id, e.target.value)}
                            >
                                <option disabled value={-999}>Seçiniz</option>
                                {props.optionValues.map(score => (
                                    <option key={score.id} value={score.value} disabled={props.optionValueType === 0 && disableOptions.includes(score.value)}>{score.value}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                );
            })}
            <div className='row'>
                <div className='col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10' />
                <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                    <button
                        className='btn btn-danger'
                        title='remove'
                        style={{ width: '100%' }}
                        onClick={clearSelectedOptions}
                    >
                        <FaRecycle size={'1.3rem'} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OptionValue;
