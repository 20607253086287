import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import userReducer from '../features/auth/userSlice'
import sessionReducer from '../features/session/sessionSlice'
import alertReducer from '../features/alert/alertSlice'
import corporationSlice from '../features/entity/corporationSlice'

export default configureStore({
  reducer: {
    auth: authReducer,
    session: sessionReducer,
    alert: alertReducer,
    user: userReducer,
    corporation: corporationSlice
  }
})