import React from "react"

import '../styles/miniStatisticCard.css'

const MiniStatisticCard = (props) => {
    return (
        <div className="statistic-card">
            <div className="info-row">
                {
                    props.data.statistic === null ?
                        <div className="spinner-border spinner-border-sm" style={{ margin: "4px" }} /> :
                        props.data.statistic
                }
            </div>
            <div className="header-row">
                {props.data.header}
            </div>
        </div>
    )
}

export default MiniStatisticCard;