import React from 'react';
import Nav from './components/Nav';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Assessment from "./pages/Assessment";
import NoPage from "./pages/NoPage";
import Session from './pages/Session';
import SessionResult from './pages/SessionResult';
import ClientResult from './pages/ClientResult';
import Login from './pages/Login';
import AuthChecker from './components/AuthChecker';
import Alert from './components/Alert';
import SystemManagement from './pages/SystemManagement';

const App = () => {
    return (
        <Router>
            <AuthChecker />
            <Nav />
            <div className='app-container'>
                <Alert />
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/:sessionCode' element={<Session />} />
                    <Route path='assessment/:assessmentId/:sessionId' element={<Assessment />} />
                    <Route path='session' element={<Session />} />
                    <Route path='sessionResult' element={<SessionResult />} />
                    <Route path='sessionResult/:sessionId' element={<SessionResult />} />
                    <Route path='clientResult/:clientAnswerHeaderId' element={<ClientResult />} />
                    <Route path='systemManagement' element={<SystemManagement />} />
                    <Route path='*' element={<NoPage />} />
                </Routes>
            </div>
        </Router >
    )
}

export default App;