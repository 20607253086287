import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearToken, selectToken } from '../features/auth/authSlice';
import parameters from '../parameters.json'
import { useNavigate } from 'react-router-dom';
import { selectUserList, setUserDetail, setUserList } from '../features/auth/userSlice';

const UserList = () => {
    const baseUrl = parameters.BASE_API_URL;
    const token = useSelector(selectToken)
    const userList = useSelector(selectUserList)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/user', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                if (data)
                    dispatch(setUserList(data))
            })
            .catch((res) => {
                console.error(res);
            });
    }, [dispatch, navigate, baseUrl, token])

    const RenderUserListItems = () => {
        if (userList.length === 0)
            return (
                <tr><th>Kullanıcı listesi çekiliyor...</th></tr>
            )

        return userList.map((user, index) => (
            <tr key={index} className='text-nowrap' onClick={() => dispatch(setUserDetail(user))}>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>{user.isActive ? 'Aktif' : 'Pasif'}</td>
            </tr>
        ))
    }

    return (
        <div className='card mb-2'>
            <div className='card-header'>
                Kullanıcı listesi
            </div>
            <div className='card-body'>
                <table className='table table-striped m-3 table-responsive table-hover'>
                    <thead>
                        <tr>
                            <th scope="col">Kullanıcı adı</th>
                            <th scope="col">Rol</th>
                            <th scope="col">Durum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {RenderUserListItems()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UserList;