import React, { useState } from "react";
import RequestButton from "../RequestButton";
import parameters from "../../parameters.json"
import { useDispatch, useSelector } from "react-redux";
import { resetCorporationDetail, selectCorporationDetail, setCorporationDetail, setCorporationList } from "../../features/entity/corporationSlice"
import { useNavigate } from "react-router-dom";
import { clearToken, selectToken } from "../../features/auth/authSlice";
import { showAlert } from "../../features/alert/alertSlice";

const Detail = () => {
    const baseUrl = parameters.BASE_API_URL;
    const token = useSelector(selectToken)
    const corporationDetail = useSelector(selectCorporationDetail)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const [saveRequested, setSaveRequested] = useState(false)

    const Cancel = () => {
        dispatch(resetCorporationDetail())
    }

    const SaveDisability = () => {
        return (!corporationDetail.name)
    }

    const Save = () => {
        const corporationRequest = corporationDetail.id ?
        {
            id: corporationDetail.id,
            name: corporationDetail.name
        } :
        {
            name: corporationDetail.name
        }

    const option = {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(corporationRequest)
    }

    const url = corporationDetail.id ?
        '/appParameter/corporation/update' :
        '/appParameter/corporation/add'

    setSaveRequested(true)

    fetch(baseUrl + url, option)
        .then((res) => {
            if (res.status === 401) {
                navigate('/login')
                dispatch(clearToken())
                return
            }

            if (res.status === 400) {
                dispatch(showAlert({ type: 'warning', text: 'Kurum eklenemedi! Farklı bir kurum adı deneyin.' }))
                dispatch(resetCorporationDetail())
            }

            if (res.ok) {
                dispatch(showAlert({ type: 'success', text: 'Kurum eklendi!' }))
                dispatch(resetCorporationDetail())
                GetCorporationList()
            }

            setSaveRequested(false)
        })
        .catch((err) => {
            setSaveRequested(false)
            console.error(err)
        });
    }

    const GetCorporationList = () => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/appParameter/corporation/all', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                if (data)
                    dispatch(setCorporationList(data))
            })
            .catch((res) => {
                console.error(res);
            });
    }

    return (
        <div className='card mb-2'>
            <div className='card-header'>
                Kurum detayı
            </div>
            <div className='card-body'>
                <div className="d-grid gap-3">
                    <input
                        type="text"
                        className="form-control"
                        aria-label="corporation"
                        aria-describedby="corporation-input"
                        value={corporationDetail.name}
                        onChange={(e) => dispatch(setCorporationDetail({ ...corporationDetail, name: e.target.value }))}
                        placeholder="kurum adı"
                    />
                </div>
            </div>
            <div className="card-footer text-end">
                <RequestButton
                    classNames="btn btn-primary"
                    onClick={Save}
                    disabled={SaveDisability()}
                    isOnRequest={saveRequested}
                    style={{ marginRight: '5px' }}
                >
                    Kaydet
                </RequestButton>
                <button
                    className="btn btn-secondary"
                    onClick={Cancel}
                    type="button"
                >
                    İptal
                </button>
            </div>
        </div>
    )
}

export default Detail;