import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        sessions: []
    },
    reducers: {
        setSessions: (state, action) => {
            state.sessions = action.payload
        },
        clearSessions: (state) => {
            state.sessions = []
        }
    }
})

export const selectSessions = state => state.session.sessions

export const { setSessions, clearSessions } = sessionSlice.actions

export default sessionSlice.reducer