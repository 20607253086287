import { createSlice } from '@reduxjs/toolkit'

export const corporationSlice = createSlice({
    name: 'corporation',
    initialState: {
        corporationList: [],
        corporationDetail: {
            name: '',
            id: null
        }
    },
    reducers: {
        setCorporationDetail: (state, action) => {
            state.corporationDetail = { ...action.payload }
        },
        setCorporationList: (state, action) => {
            state.corporationList = action.payload
        },
        resetCorporationDetail: (state) => {
            state.corporationDetail = {
                name: '',
                id: null
            }
        }
    }
})

export const selectCorporationDetail = state => state.corporation.corporationDetail
export const selectCorporationList = state => state.corporation.corporationList

export const {
    setCorporationDetail,
    setCorporationList,
    resetCorporationDetail
} = corporationSlice.actions

export default corporationSlice.reducer