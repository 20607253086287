import React, { useEffect, useState } from 'react';
import MiniStatisticCard from '../components/MiniStatisticCard';
import { selectToken, clearToken } from '../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux';

import parameters from '../parameters.json'
import { useNavigate } from 'react-router-dom';
import '../styles/home.css'

const baseUrl = parameters.BASE_API_URL;

const Home = () => {
    const token = useSelector(selectToken)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [statistics, setStatistics] = useState(
        {
            activeSessionCount: null,
            totalAssessmentCount: null,
            totalParticipantCount: null
        }
    );

    useEffect(() => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/statistic/getHomePageStatistics', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                if (data)
                    setStatistics(data)
            })
            .catch((res) => {
                console.error(res);
            });
    }, [token, dispatch, navigate])

    const statisticsRow = () => {
        return (
            <div className='statistics-container'>
                <MiniStatisticCard data={{ header: 'Envanter', statistic: statistics.totalAssessmentCount }} />
                <MiniStatisticCard data={{ header: 'Aktif Oturum', statistic: statistics.activeSessionCount }} />
                <MiniStatisticCard data={{ header: 'Katılımcı', statistic: statistics.totalParticipantCount }} />
            </div>
        )
    }

    return (
        <div>
            {statisticsRow()}
        </div>
    );
};

export default Home;

