import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToken } from "../features/auth/authSlice";
import { showAlert } from "../features/alert/alertSlice";
import parameters from '../parameters.json'
import RequestButton from "../components/RequestButton";

const Login = () => {
    const baseUrl = parameters.BASE_API_URL;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginRequested, setLoginRequested] = useState(false);

    const LoginRequest = () => {
        if (!username || !password) {
            dispatch(showAlert({ type: 'warning', text: 'Kullanıcı adı ve şifre alanlarını doldurun.' }))
            return
        }

        setLoginRequested(true)

        const model = {
            username,
            password
        }

        fetch(baseUrl + '/auth/login',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(model)
            })
            .then((res) => {
                setLoginRequested(false)
                if (res.ok)
                    return res.text();
                else
                    dispatch(showAlert({ type: 'danger', text: 'Kullanıcı adı ve şifre hatalı.' }))
            })
            .then((data) => {
                if (data[0] === '"')
                    data = data.slice(1, -1)

                dispatch(setToken(data))
                setLoginRequested(false)
                navigate('/session')
            })
            .catch((err) => {
                setLoginRequested(false)
                console.error(err)
            });
    }

    return (
        <div className="d-flex justify-content-center" style={{ padding: '5px' }}>
            <div className="card col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-6 col-xxl-6">
                <div className="card-header">
                    Giriş yap
                </div>
                <div id="login-alert" />
                <div className="card-body">
                    <div className="d-grid gap-3">
                        <input
                            type="text"
                            className="form-control"
                            aria-label="username"
                            aria-describedby="username-input"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="kullanıcı adı"
                        />
                        <input
                            type="password"
                            className="form-control"
                            aria-label="password"
                            aria-describedby="password-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="şifre"
                        />
                        {/* <button
                            className="btn btn-primary"
                            onClick={LoginRequest}
                            type="button"
                        >
                            Giriş yap
                        </button> */}
                        <RequestButton
                            classNames="btn btn-primary"
                            onClick={LoginRequest}
                            isOnRequest={loginRequested}
                        >
                            Giriş yap
                        </RequestButton>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Login;