import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectToken } from "../features/auth/authSlice";
import parameters from '../parameters.json'

const SessionResult = () => {
    const { sessionId } = useParams();

    const [sessionResult, setSessionResult] = useState(null)
    const token = useSelector(selectToken)

    useEffect(() => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(parameters.BASE_API_URL + '/session/getSessionResults?sessionId=' + sessionId, option)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setSessionResult(data);
            })
            .catch((res) => {
                console.error(res);
            });
    }, [token, sessionId])

    const SessionInfo = () => {
        return (
            <div className="row text-center">
                <h1>{sessionResult.sessionDescription}</h1>
                <h3>{sessionResult.assessmentName}</h3>
            </div>
        )
    }

    const SessionResult = () => {
        return (
            <div className="container">
                <h4>Genel sonuç:</h4>
                <div className="row">
                    {
                        sessionResult.sessionCategoryResults?.map((categoryResult) =>
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" key={categoryResult.categoryId}>
                                <div className="mb-2">
                                    <div className="card">
                                        <div className="card-header">
                                            <strong>{categoryResult.title}:</strong> {categoryResult.result}
                                        </div>
                                        {
                                            categoryResult.resultDescription ?
                                                <div className="card-body">
                                                    <p>{categoryResult.resultDescription}</p>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    const SessionClientResults = () => {
        return (
            <div className="container">
                <h4>Katılımcı bazlı sonuç:</h4>
                <div className="row d-flex">
                    <span className="text-end">Toplam katılım: {sessionResult.clientResults?.length}</span>
                </div>
                <div className="row">
                    <div className="accordion" id="client-result-accordion">
                        {
                            sessionResult.clientResults?.map((result, ix) => {
                                return (
                                    <div key={ix}>
                                        <div className="accordion-item" key={ix}>
                                            <h2 className="accordion-header" id={'heading-' + ix}>
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse-' + ix} aria-expanded="true" aria-controls={'collapse-' + ix}>
                                                    <b>{result.client.name} {result.client.surname} {result.client.nickName}</b>
                                                </button>
                                            </h2>
                                            <div id={'collapse-' + ix} className="accordion-collapse collapse" aria-labelledby={'heading-' + ix} data-bs-parent="#client-result-accordion">
                                                <div className="accordion-body">
                                                    {
                                                        result.results.map((categoryResult, ixx) => {
                                                            return (
                                                                <div key={ixx}>
                                                                    <strong>{categoryResult.title}</strong>: <span>{categoryResult.result}</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    if (!sessionResult) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary spinner-border-sm" style={{ margin: "4px", width: "3rem", height: "3rem" }} role="status" />
            </div>
        )
    }

    return (
        <>
            {SessionInfo()}
            {SessionResult()}
            <hr />
            {SessionClientResults()}
        </>
    )
}

export default SessionResult;