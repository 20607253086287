import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearToken, selectToken } from '../features/auth/authSlice';
import { selectSessions, setSessions } from '../features/session/sessionSlice';
import { FaFileExcel, FaQrcode, FaSquarePollVertical } from 'react-icons/fa6'
import SessionQrModal from '../components/SessionQrModal';
import SessionQrModalButton from '../components/SessionQrModalButton';
import parameters from '../parameters.json'
import RequestButton from '../components/RequestButton';
import { showAlert } from '../features/alert/alertSlice';
import Paging from '../components/Paging';
import * as XLSX from 'xlsx';

const Session = () => {
    const baseUrl = parameters.BASE_API_URL;
    const baseUIUrl = parameters.BASE_UI_URL;
    const [sessionDescription, setSessionDescription] = useState('')
    const [sessionExpirationDate, setSessionExpirationDate] = useState('')
    const [clientObserveResultStatu, setClientObserveResultStatu] = useState(true)
    const [needToClientGroupCode, setNeedToClientGroupCode] = useState(false)
    const [assessments, setAssessments] = useState([])
    const [selectedAssessment, setSelectedAssessment] = useState('')
    const [corporations, setCorporations] = useState([])
    const [selectedCorporation, setSelectedCorporation] = useState('')
    const [qr, setQR] = useState('')
    const [assessmentLink, setAssessmentLink] = useState('')
    const [assessmentDesc, setAssessmentDesc] = useState('')
    const [saveSessionRequested, setSaveSessionRequested] = useState(false)
    const [allPaginationButtonsDisabled, setAllPaginationButtonsDisabled] = useState(false)

    const [sessionActivePage, setSessionActivePage] = useState(1)
    const [sessionSkip, setSessionSkip] = useState(0)
    const [sessionTake] = useState(5)
    const [pageCount, setPageCount] = useState(0)

    const [exportResultToExcelRequested, setExportResultToExcelRequested] = useState(false);

    const navigate = useNavigate();
    const token = useSelector(selectToken)

    const dispatch = useDispatch()
    const sessionData = useSelector(selectSessions)

    useEffect(() => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/session/getSessions?skip=' + sessionSkip + '&take=' + sessionTake, option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                if (data)
                    dispatch(setSessions(data))
            })
            .catch((res) => {
                console.error(res);
            });

        fetch(baseUrl + '/assessment/getAssessments?skip=0&take=100', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                setSelectedAssessment(data[0].id)
                setAssessments(data);
            })
            .catch((res) => {
                console.error(res);
            });


        fetch(baseUrl + '/appParameter/corporation/all', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                setCorporations(data)
                setSelectedCorporation(data[0].id)
            })
            .catch((res) => {
                console.error(res);
            });
    }, [token, baseUrl, navigate, dispatch, sessionSkip, sessionTake])

    useEffect(() => {
        let pagesCount = sessionData.totalCount % sessionTake === 0 ? (sessionData.totalCount / sessionTake) : (parseInt(sessionData.totalCount / sessionTake) + 1)
        setPageCount(pagesCount)
    }, [sessionData.totalCount, sessionTake])

    const GetData = () => {
        GetSessions(sessionSkip, sessionTake)
        GetAssessments()
        GetCorporation()
    }

    const GetSessions = (_skip, _take) => {
        setAllPaginationButtonsDisabled(true);

        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/session/getSessions?skip=' + _skip + '&take=' + _take, option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                setAllPaginationButtonsDisabled(false);
                return res.json();
            })
            .then((data) => {
                if (data)
                    dispatch(setSessions(data))
            })
            .catch((res) => {
                setAllPaginationButtonsDisabled(false);
                console.error(res);
            });
    }

    const GetAssessments = () => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/assessment/getAssessments?skip=0&take=100', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                setSelectedAssessment(data[0].id)
                setAssessments(data);
            })
            .catch((res) => {
                console.error(res);
            });
    }

    const GetCorporation = () => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/appParameter/corporation/all', option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                return res.json();
            })
            .then((data) => {
                setCorporations(data)
                setSelectedCorporation(data[0].id)
            })
            .catch((res) => {
                console.error(res);
            });
    }

    const AssessmentPageQR = (sessionId, assessmentId, description) => {
        const desiredAssessmentLink = baseUIUrl
            + '/assessment/'
            + assessmentId
            + '/'
            + sessionId;

        fetch('https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='
            + desiredAssessmentLink
        )
            .then(res => {
                setQR(res.url)
                setAssessmentLink(desiredAssessmentLink)
                setAssessmentDesc(description)
            })
    }

    const GetSessionResult = (sessionId) => {
        navigate('/sessionResult/' + sessionId)
    }

    const ExportResultToExcel = (sessionId) => {
        setExportResultToExcelRequested(true)

        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(parameters.BASE_API_URL + '/session/getClientAnswersForExcelExport?sessionId=' + sessionId, option)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                for (let i = 0; i < data.clientAnswers.length; i++) {
                    data.clientAnswers[i].Tarih = data.clientAnswers[i].Tarih.replace('T', ' ')
                }

                // Verilerin yer alacağı bir çalışma kitabı (Workbook) oluştur
                const wb = XLSX.utils.book_new();

                // Veri sayfasını oluştur
                const ws = XLSX.utils.json_to_sheet(data.clientAnswers);

                // Header'ı bold yapmak için stil oluştur
                const boldHeaderStyle = { font: { bold: true } };

                // Header'ın hücre aralığını al
                const range = XLSX.utils.decode_range(ws['!ref']);

                // Header'ın hücrelerine bold stili uygula
                for (let i = range.s.c; i <= range.e.c; i++) {
                    const headerCell = XLSX.utils.encode_cell({ r: range.s.r, c: i });
                    ws[headerCell].s = boldHeaderStyle;
                }

                // Çalışma kitabına sayfayı ekle
                XLSX.utils.book_append_sheet(wb, ws, "Kullanıcı yanıtları");

                let desc = data.sessionDescription + " - " + data.assessmentName;

                // Dosyayı indirmek için dosya adı ve türü belirt
                const fileName = desc + ".xlsx";

                // Dosyayı oluşturmak ve indirmek için blob oluştur
                XLSX.writeFile(wb, fileName);

                setExportResultToExcelRequested(false)
            })
            .catch((res) => {
                console.error(res);

                dispatch(showAlert({ type: 'danger', text: 'Kullanıcı sonuçları dışa aktarılırken bir hata oldu!' }))
                setExportResultToExcelRequested(false)
            });
    }

    const ChangeSessionStatus = (sessionId) => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/session/changeStatus?sessionId=' + sessionId, option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                if (res.ok) {
                    GetData()
                }
            })
            .catch((err) => console.error(err));
    }

    const ExtendExpirationDate = (sessionId) => {
        let option = {
            headers: { Authorization: 'Bearer ' + token }
        }

        fetch(baseUrl + '/session/extendExpirationDate?sessionId=' + sessionId, option)
            .then((res) => {
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                if (res.ok) {
                    GetData()
                }
            })
            .catch((err) => console.error(err));
    }

    const SessionList = () => {
        let body = null

        if (sessionData === null) {
            body = <tr><th>Oturum listesi çekiliyor...</th></tr>
        }
        else if (sessionData.values.length === 0) {
            body = <tr><th>Henüz oluşturulmuş bir oturum bulunmuyor.</th></tr>
        }
        else {
            body = sessionData.values.map((session, index) => (
                <tr key={session.id} className='text-nowrap'>
                    <td>{session.description}</td>
                    <td>{session.assessmentName}</td>
                    <td>{session.clientAnswerCount}</td>
                    <td>{session.createdDate.split('T')[0]}</td>
                    <td>
                        {session.expirationDate.split('T')[0]}
                        <button
                            className='btn btn-outline-primary'
                            style={{ marginLeft: '5px' }}
                            onClick={() => ExtendExpirationDate(session.id)}
                        >
                            +1
                        </button>
                    </td>
                    <td>
                        <button
                            type="button"
                            className={session.isActive ? 'btn btn-primary' : 'btn btn-outline-secondary'}
                            onClick={() => ChangeSessionStatus(session.id)}
                        >
                            {session.isActive ? 'Aktif' : 'Pasif'}
                        </button>
                    </td>
                    <td>
                        <SessionQrModalButton buttonInner={<FaQrcode size={'1.7em'} />} classNames={'btn btn-outline-primary'} onClick={() => AssessmentPageQR(session.id, session.assessmentId, session.description)} />
                        <button className='btn btn-outline-warning' onClick={() => GetSessionResult(session.id)}><FaSquarePollVertical size={'1.7em'} /></button>
                        <RequestButton
                            classNames='btn btn-outline-success'
                            onClick={() => ExportResultToExcel(session.id)}
                            isOnRequest={exportResultToExcelRequested}
                        >
                            <FaFileExcel size={'1.7em'} />
                        </RequestButton>
                    </td>
                </tr>
            ))
        }

        return (
            <div className='col-md-8'>
                <div className='card mb-2'>
                    <div className='card-header'>
                        Oturum listesi
                    </div>
                    <div className='card-body'>
                        <div style={{ overflowX: 'auto' }}>
                            <table className='table table-striped table-responsive'>
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: '10%' }}>Oturum</th>
                                        <th scope="col" style={{ width: '10%' }}>Envanter</th>
                                        <th scope="col" style={{ width: '10%' }}>Yanıt Sayısı</th>
                                        <th scope="col" style={{ width: '10%' }}>Tarih</th>
                                        <th scope="col" style={{ width: '10%' }}>Son Kullanım</th>
                                        <th scope="col" style={{ width: '10%' }}>Aktiflik</th>
                                        <th scope="col" style={{ width: '10%' }}>Aksiyonlar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {body}
                                </tbody>
                            </table>
                        </div>
                        <Paging activePage={sessionActivePage} pageCount={pageCount} onClick={(page) => changeSessionPage(page)} allButtonDisabled={allPaginationButtonsDisabled} />
                        <SessionQrModal qrCode={qr} link={assessmentLink} sessionDescription={assessmentDesc} />
                    </div>
                </div>
            </div>
        )
    }

    const changeSessionPage = (page) => {
        setSessionActivePage(page)
        let newSkip = sessionTake * (page - 1)
        setSessionSkip(newSkip)

        GetSessions(newSkip, sessionTake)
    }

    const handleDescriptionChange = (event) => {
        setSessionDescription(event.target.value);
    }

    const handleExpirationDateChange = (event) => {
        setSessionExpirationDate(event.target.value)
    }

    const handleClientObserveResultStatuChange = (event) => {
        setClientObserveResultStatu(event.target.checked)
    }

    const handleNeedToClientGroupCode = (event) => {
        setNeedToClientGroupCode(event.target.checked)
    }

    const NewSessionZone = () => {
        return (
            <div className='col-md-4'>
                <div className="card mb-2">
                    <div className="card-header">
                        Yeni oturum oluştur
                    </div>
                    <div className="card-body">
                        <div className='input-group mb-3'>
                            <span className="input-group-text">Envanter</span>
                            <select
                                className="form-select"
                                value={selectedAssessment}
                                onChange={e => setSelectedAssessment(e.target.value)}
                            >
                                {
                                    assessments.map((assessment) => {
                                        return (
                                            <option key={assessment.id} id={assessment.id} value={assessment.id}>{assessment.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Oturum adı</span>
                            <input
                                type="text"
                                value={sessionDescription}
                                onChange={handleDescriptionChange}
                                className="form-control"
                                placeholder="örn: Tim Akademi 2024" />
                        </div>
                        <div className='input-group mb-3'>
                            <span className="input-group-text">Kurum</span>
                            <select
                                className="form-select"
                                value={selectedCorporation}
                                onChange={e => setSelectedCorporation(e.target.value)}
                            >
                                {
                                    corporations.map((corporation) => {
                                        return (
                                            <option key={corporation.id} id={corporation.id} value={corporation.id}>{corporation.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Bitiş tarihi</span>
                            <input
                                type="date"
                                className="form-control"
                                value={sessionExpirationDate}
                                onChange={handleExpirationDateChange} />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <label className="form-check-label" htmlFor="clientObserveResultStatu">{clientObserveResultStatu ? 'Kullanıcılar sonuçlarını görür' : 'Kullanıcılar sonuçlarını göremez'}</label>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="clientObserveResultStatu"
                                checked={clientObserveResultStatu}
                                onChange={handleClientObserveResultStatuChange}
                            />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <label className="form-check-label" htmlFor="needToClientGroupCode" >{needToClientGroupCode ? 'Grup kodu istenir' : 'Grup kodu istenmez'}</label>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="needToClientGroupCode"
                                checked={needToClientGroupCode}
                                onChange={handleNeedToClientGroupCode}
                            />
                        </div>
                    </div>
                    <div className='card-footer text-end'>
                        <RequestButton
                            classNames='btn btn-primary'
                            onClick={SaveSession}
                            disabled={!selectedAssessment || !sessionDescription}
                            isOnRequest={saveSessionRequested}
                        >
                            Oluştur
                        </RequestButton>
                    </div>
                </div>
            </div>
        )
    }

    const SaveSession = () => {
        if (!selectedAssessment || !sessionDescription)
            return;

        const newSession = {
            assessmentId: selectedAssessment,
            description: sessionDescription,
            expirationDate: sessionExpirationDate,
            clientObserveResult: clientObserveResultStatu,
            needToClientGroupCode: needToClientGroupCode,
            isActive: true,
            corporationId: selectedCorporation
        }
        let option = {
            headers: {
                Authorization: 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(newSession)
        }

        setSaveSessionRequested(true)

        fetch(baseUrl + '/session/addSession', option)
            .then((res) => {
                setSaveSessionRequested(false)
                if (res.status === 401) {
                    navigate('/login')
                    dispatch(clearToken())
                    return
                }

                if (res.ok) {
                    dispatch(showAlert({ type: 'success', text: 'Kayıt edildi!' }))
                    setSessionExpirationDate('')
                    setSessionDescription('')
                    GetData()
                }
            })
            .catch((err) => {
                setSaveSessionRequested(false)
                console.error(err)
            });
    }

    return (
        <div>
            <h1>Oturum Yönetimi</h1>
            <hr />
            <div className='col-md-12'>
                <div className='row'>
                    {NewSessionZone()}
                    {SessionList()}
                </div>
            </div>
        </div>
    )
}

export default Session;