import React, { useEffect, useState } from "react";

const Paging = (props) => {
    const [prevButtonVisible, setPrevButtonVisibility] = useState(false);
    const [nextButtonVisible, setNextButtonVisibility] = useState(true);

    useEffect(() => {
        refreshPagination();
    }, [props.pageCount, props.activePage, props.allButtonDisabled])

    function refreshPagination() {
        setDirectionButtonVisibility(props.activePage);
    }

    function changePage(_page) {
        if (_page === props.activePage)
            return;

        props.onClick(_page);

        setDirectionButtonVisibility(_page);
    }

    function setDirectionButtonVisibility(_activePage) {
        setPrevButtonVisibility(_activePage > 1);
        setNextButtonVisibility(_activePage < props.pageCount);
    }

    function nextPage() {
        if (props.activePage < props.pageCount) {
            changePage(props.activePage + 1);
        }
    }

    function previousPage() {
        if (props.activePage > 1) {
            changePage(props.activePage - 1);
        }
    }

    function getVisiblePages() {
        const totalPages = props.pageCount;
        const activePage = props.activePage;
        let pages = [];

        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (activePage !== 1)
                pages.push(1);

            if (activePage > 3) pages.push('...');
            if (activePage > 2) pages.push(activePage - 1);

            pages.push(activePage);
            if (activePage < totalPages - 1) pages.push(activePage + 1);
            if (activePage < totalPages - 2) pages.push('...');

            if (activePage !== totalPages)
                pages.push(totalPages);
        }

        return pages;
    }

    function renderPages() {
        const pages = getVisiblePages();
        return pages.map((page, index) => (
            <li
                key={index}
                className={props.activePage === page ? 'page-item active' : 'page-item'}
            >
                {typeof page === 'number' ? (
                    <button className="page-link" onClick={() => changePage(page)} disabled={props.allButtonDisabled}>{page}</button>
                ) : (
                    <span className="page-link">{page}</span>
                )}
            </li>
        ));
    }

    return (
        <div className="center-row">
            <ul className="pagination">
                <li className={prevButtonVisible ? "page-item" : "page-item disabled"}>
                    <button
                        className="page-link"
                        onClick={previousPage}
                        aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </button>
                </li>
                {renderPages()}
                <li className={nextButtonVisible ? "page-item" : "page-item disabled"}>
                    <button
                        className="page-link"
                        onClick={nextPage}
                        aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default Paging;
